<template>
  <div class="main_container">
    <div class="row mb-3">
      <div class="text-left col-12">
        <span>Opérations bancaires></span>
      </div>
    </div>
    <h2 class="page_title text-center op-title">OPERATIONS BANCAIRES</h2>
    <div class="row">
      <div class="col-12 text-center">
        <button class="btn btn-blue pl-1 pr-1 op-btn"
                v-if="(user[1] == 'FER' && (user[0] == 'DAF'|| user[0]=='DCG'))||(user[1] == 'FER' && user[0] == 'ADMIN')"
                data-toggle="modal"
                ref="modal_button"
                data-target="#exampleModal">Importer relevés bancaires</button>
        
        <button class="btn btn-blue ml-2 pl-1 pr-1 op-btn"
                v-if="(user[1] == 'FER' && (user[0] == 'DAF'|| user[0]=='DCG'))||(user[1] == 'FER' && user[0] == 'ADMIN')"
                data-toggle="modal"
                ref="modal_button2"
                data-target="#exampleModal3">Importer comptes bancaires</button>        
        <router-link to="listeDesTransactions"
                     custom
                     v-slot="{ href, navigate }">
          <a class="btn btn-blue ml-2 pl-1 pr-1 op-btn"
             :href="href"
             role="button"
             @click="navigate">
            Voir transactions
          </a>
        </router-link>
      </div>
    </div>
    <div class="row justify-content-center pt-5">
      <div class="form-group">
        <label for=""></label>
        <select class="form-control" 
                name="" 
                id=""
                v-model="year"
                @change="changeGraph()">
          <option :value="Number(year)-5">{{Number(year)-5}}</option>
          <option :value="Number(year)-4">{{Number(year)-4}}</option>
          <option :value="Number(year)-3">{{Number(year)-3}}</option>
          <option :value="Number(year)-2">{{Number(year)-2}}</option>
          <option :value="Number(year)-1">{{Number(year)-1}}</option>
          <option :value="year">{{year}}</option>
          <option :value="Number(year)+1">{{Number(year)+1}}</option>
          <option :value="Number(year)+2">{{Number(year)+2}}</option>
          <option :value="Number(year)+3">{{Number(year)+3}}</option>
          <option :value="Number(year)+4">{{Number(year)+4}}</option>
          <option :value="Number(year)+5">{{Number(year)+5}}</option>
        </select>
      </div>
    </div>
    <div class="row justify-content-center pt-2">
      <div class="col-9"
           v-if="loaded==true">
        <div style="width: 800px; margin: auto">
          <BarChart :chartdata="randomChart1"
                    :options="optionsChart1" />
        </div>
      </div>
    </div>
    <div class="row">
      <Notif :notif="notif" />
    </div>
    <div class="row pt-5">
      <div class="col-12">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col"
                  class="th-blue">Détails du compte</th>
              <th scope="col"
                  class="th-blue">Transactions non categorisés</th>
              <th scope="col"
                  class="th-blue">Montant en banque</th>
              <th scope="col"
                  class="th-blue">Date</th>
            </tr>
          </thead>
          <tbody class="op-body-tr">
            <tr v-for="(compte, ckey) in comptes.donnees"
                :key="ckey"
                @click="transaction(compte.id)">
              <td>
                {{ compte.banque.single }}
              </td>
              <td class="text-center">
                <span class="op-transactions">{{ compte.transactions_non_categorisees }}</span>
              </td>
              <td class="op-text text-center">{{ Math.round(compte.montant).toLocaleString() }} FCFA</td>
              <td class="op-text text-center">
                <span>
                  {{ compte.date }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- Modal relevé bancaire-->
    <div class="modal fade"
         id="exampleModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"
                id="exampleModalLabel">Importer Relevés bancaires</h5>
            <button type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label for=""
                >Relevé bancaire <sup><span class="req-star">*</span></sup></label
                >
                <div class="custom-file">
                  <label class="custom-file-label">
                    {{ inputFiles.releve_bancaire }}
                  </label>

                  <input type="file"
                         name="etat_comptable"
                         class="custom-file-input"
                         id="releve_bancaire"
                         ref="releve_bancaire"
                         v-on:change="handleFileUpload('releve_bancaire')"
                         accept=".xlsx,.csv" />
                  <span v-if="form.errors().has('releve_bancaire')"
                        v-text="form.errors().get('releve_bancaire')"
                        class="errorMsg"> </span>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal">Annuler</button>
            <button type="button"
                    class="btn btn-primary"
                    :disabled="url == ''"
                    @click="submit('releve_bancaire')">Importer</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal etat comptable-->
    <div class="modal fade"
         id="exampleModal2"
         tabindex="-1"
         role="dialog"
         aria-labelledby="exampleModalLabel2"
         aria-hidden="true">
      <div class="modal-dialog"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"
                id="exampleModalLabel2">Importer Etats comptables</h5>
            <button type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label for=""
                >Etats Comptables <sup><span class="req-star">*</span></sup></label
                >
                <div class="custom-file">
                  <label class="custom-file-label">
                    {{ inputFiles.etat_comptable }}
                  </label>

                  <input type="file"
                         name="etat_comptable"
                         class="custom-file-input"
                         id="etat_comptable"
                         ref="etat_comptable"
                         v-on:change="handleFileUpload('etat_comptable')"
                         accept=".xlsx,.csv" />
                  <span v-if="form.errors().has('etat_comptable')"
                        v-text="form.errors().get('etat_comptable')"
                        class="errorMsg"> </span>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal">Annuler</button>
            <button type="button"
                    class="btn btn-primary"
                    :disabled="url == ''"
                    @click="submit('etat_comptable')">Importer</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal compte bancaire-->
    <div class="modal fade"
         id="exampleModal3"
         tabindex="-1"
         role="dialog"
         aria-labelledby="exampleModalLabel3"
         aria-hidden="true">
      <div class="modal-dialog"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"
                id="exampleModalLabel3">Importer comptes bancaires</h5>
            <button type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label for=""
                >Comptes Bancaires <sup><span class="req-star">*</span></sup></label
                >
                <div class="custom-file">
                  <label class="custom-file-label">
                    {{ inputFiles.compte_bancaire }}
                  </label>

                  <input type="file"
                         name="compte_bancaire"
                         class="custom-file-input"
                         id="compte_bancaire"
                         ref="compte_bancaire"
                         v-on:change="handleFileUpload('compte_bancaire')"
                         accept=".xlsx,.csv" />
                  <span v-if="form.errors().has('compte_bancaire')"
                        v-text="form.errors().get('compte_bancaire')"
                        class="errorMsg"> </span>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal">Annuler</button>
            <button type="button"
                    class="btn btn-primary"
                    :disabled="url == ''"
                    @click="submit('compte_bancaire')">Importer</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HTTP from "@/Api/auth"
import form from "vuejs-form"
import { mapActions, mapMutations, mapGetters } from "vuex"
import BarChart from "@/components/shared/BarChart"
import utils from "@/assets/js/utils"
import Notif from "@/components/shared/Toast"

export default {
  components: {
    BarChart,
    Notif
  },
  name: "OperationsBancaires",
  data() {
    return {
      selectedFile: null,
      url: "",
      year: new Date().getFullYear(),
      randomChart1: {
        labels: Array.from({ length: 12 }, (e, i) => {
          return new Date(null, i + 1, null).toLocaleDateString("fr", { month: "short" })
        }),
        datasets: [
          {
            label: "Encaissements",
            data: [30, 20, 40, 50, 5],
            backgroundColor: ["blue"]
          },
          {
            label: "Décaissements",
            data: [20, 10, 5, 6, 10],
            backgroundColor: ["gray"]
          }
        ]
      },
      listExtensionFiles: [],
      optionsChart1: {
        responsive: true,
        maintainAspectRatio: false
      },
      loaded:false,
      notif: {
        activated: "",
        type: "",
        message: ""
      },
      form: form({
        etat_comptable: "",
        compte_bancaire: "",
        releve_bancaire:"" 
      })
        .rules({
          etat_comptable: "required",
          compte_bancaire: "required",
          releve_bancaire:"required" 
        })
        .messages({
          etat_comptable: "Ce champ est requis",
          compte_bancaire: "Ce champ est requis",
          releve_bancaire:"Ce champ est requis" 
        }),
      uploadfile: "etat_comptable",
      inputFiles: { 
        etat_comptable: "",
        compte_bancaire: "", 
        releve_bancaire:"" 
      }
    }
  },
  created() {
    this.loadBankAccounts()
    this.loadGraphTransactions({annee:this.year})
    this.listExtensionFiles = utils.formaFichierAccepte
    const userdetails = JSON.parse(localStorage.getItem("user"))
    this.user = userdetails.user_role.split("_")
  },
  watch: {
    files() {
      if (this.files && this.files["url"]) {
        this.form[this.uploadfile] = process.env.VUE_APP_UPLOAD + this.files["url"]
        this.url = this.form[this.uploadfile]
      }
    },
    graphTransactions(){
      if (this.graphTransactions) {
        console.log("here")
        this.loaded=true
        this.randomChart1.datasets[0].data=this.graphTransactions.donnees.encaissement
        this.randomChart1.datasets[0].backgroundColor=this.graphTransactions.donnees.encaiscolor
        this.randomChart1.datasets[1].data=this.graphTransactions.donnees.decaissement
        this.randomChart1.datasets[1].backgroundColor=this.graphTransactions.donnees.decaiscolor
        this.setGraphTransactions("")
      }
    }
  },
  computed: {
    ...mapGetters(["files", "comptes","successTransaction","errorsTransaction","graphTransactions"])
  },
  methods: {
    ...mapActions(["saveFile", "loadBankAccounts","importCompte","loadGraphTransactions"]),
    ...mapMutations(["setGraphTransactions",]),
    handleFileUpload(filename) {
      this.inputFiles[filename] = this.$refs[filename].files[0].name
      if (!this.inputFiles[filename].includes(" ")) {
        const extension = this.inputFiles[filename].split(".")[1]
        if (this.listExtensionFiles.includes(extension)) {
          this.disabled = true
          this.uploadfile = filename
          this.saveFile(this.$refs[filename].files[0])
        } else {
          this.inputFiles[filename] = ""
          this.notif.message = "Mauvais format du fichier !!"
          this.notif.type = "toast-danger"
          this.notif.activated = "activated"
          setTimeout(
            function() {
              this.notif.activated = ""
            }.bind(this),
            5000
          )
        }
      }else{
        this.inputFiles[filename] = ""
        this.notif.message = "Vérifiez qu'il n'y a pas d'espaces dans le nom du fichier"
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          3000
        )
      }
      // this.selectedFile = this.$refs[filename].files[0]
      // this.saveFile(this.$refs[filename].files[0])
    },
    transaction(id) {
      this.$router.push({ name: "TransactionsByCompte", params: { id: id } })
    },
    changeGraph(){
      this.loaded=false
      this.loadGraphTransactions({annee:this.year})
    },
    submit(type) {
      switch (type) {
        case 'releve_bancaire':
          setTimeout(() => {
            HTTP.post(process.env.VUE_APP_AUTHURL + "transactions", {
              url: this.url
            }).then(() => {
              this.$refs["modal_button"].click()
              this.$router.push({ name: "ListeDesTransactions" })
            })
          }, 200)
          break
        case 'compte_bancaire':
          this.importCompte({url:this.url})
          break
        default:
          console.log('ok')
          break
      }
    }
  },
}
</script>

<style>
@import "./operationsBancaires.css";
</style>

<style scoped>
.op-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #000000;
}

/* .op-btn {
  height: 40px;
  background: #0097a9;
  border-radius: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
} */
.op-body-tr {
  cursor: pointer;
}

.op-head-tr {
  background: #0097a9;
  color: white;
  font-family: Roboto;
  font-size: 13.7309px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.225em;
  color: #ffffff;
}

.op-account-name {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  line-height: 11px;
  text-align: center;
  letter-spacing: 0.055em;
  color: #000000;
}

.op-transactions {
  background: rgba(229, 229, 229, 0.46);
  border-radius: 2.28848px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13.7309px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.225em;
  color: #000000;
  padding: 10px;
}

.op-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13.7309px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.225em;
  color: #000000;
}

a {
  text-decoration: none;
  color: #000000;
}
.op-btn {
  width: 20vw;
}
.op-btn-link {
  padding-top: 12px;
}
</style>
